/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useEffect, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import classNames from 'classnames';
import Format from 'types/format.type';
import Icon from 'components/ui-components-v2/Icon';
import SearchField from 'components/ui-components/SearchField';
import Typography from 'components/ui-components-v2/Typography';
import { SearchFieldInputKey } from 'components/ui-components/SearchField/components';
import { FormatsListItems } from './components/formats';
import './styles/main.scss';

interface Props {
    title?: string; // The title on the top
    compact?: boolean; // When true, the search input is compact
    selectable?: boolean; // If this is true, each format will have a checkbox and there is an option to enable all formats at the same time
    items: Item[];
    selectedItems?: string[]; // The selected items
    filters?: string[]; // The filters that are used to filter the items in the <SearchField>
    defaultActiveItem?: string; // This is the active item key that is currently selected.
    onClickItem?: (format: Item) => void; // When a format is clicked (for example, to make it active in the single view)
    onChangeSelectedItems?: (newSelectedFormats: string[]) => void; // Sends back the new array of selected formats
    onClose?: () => void;
}

export type Item = Format | { key: string; title: string };

const FormatsList: React.FC<Props> = ({
    title = 'Formats',
    compact,
    selectable = true,
    items,
    selectedItems = [],
    filters,
    defaultActiveItem,
    onClickItem = () => {},
    onChangeSelectedItems = () => {},
    onClose
}) => {
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [activeItem, setActiveItem] = useState<string>(defaultActiveItem || '');
    const [filteredItems, setFilteredItems] = useState<Item[]>([]);
    const [localSelectedItems, setLocalSelectedItems] = useState<string[]>(selectedItems);
    const [selectedFilters, setSelectedFilters] = useState<string[]>([]);

    useEffect(() => {
        // If the items don't have checkboxes, we set the first item as active
        if (!selectable && items.length > 0 && !defaultActiveItem) {
            handleClickItem(items[0]);
        }
    }, [items.length]);

    // Filter the items based on the search term and the selected filters
    useEffect(() => {
        let newFilteredItems = items.filter((item: Item) => {
            return item.title?.toLowerCase().includes(searchTerm.toLowerCase());
        });

        if (selectedFilters?.length) {
            newFilteredItems = newFilteredItems.filter((item: Item) => {
                const formatItem = item as Format;
                if (!formatItem.sets) return false;
                return formatItem.sets.some((set) => selectedFilters.includes(set));
            });
        }

        setFilteredItems(newFilteredItems);
    }, [items.length, searchTerm, selectedFilters?.length]);

    const handleNewSelectedItems = (newSelectedItems: string[]) => {
        setLocalSelectedItems(newSelectedItems);
        onChangeSelectedItems(newSelectedItems);
    };

    // Handle the actions if the user checks or unchecks a format
    const onSelectItem = (item: Item) => {
        let newSelectedItems = [...localSelectedItems];

        if (newSelectedItems.includes(item.key)) {
            newSelectedItems = newSelectedItems.filter((selectedFormat) => selectedFormat !== item.key);
        } else {
            newSelectedItems.push(item.key);
        }

        handleNewSelectedItems(newSelectedItems);
    };

    const handleClickItem = (item: Item) => {
        // If selectable, select the time. If not, it functions as an onClick that actives an item
        if (selectable) {
            onSelectItem(item);
            return;
        }

        setActiveItem(item.key);
        onClickItem(item);
    };

    // Handles the changes of the searchQuery and the filters
    const handleChange = (_key: SearchFieldInputKey, searchTerm = '', _isSearchCanceled: boolean | undefined, filters?: string[]) => {
        setSearchTerm(searchTerm);

        if (filters) {
            setSelectedFilters(filters);
        }
    };

    return (
        <div className="formats-list">
            <div className="formats-list__header">
                <Typography variant="h5">{title}</Typography>
                {onClose && (
                    <IconButton onClick={onClose} size="small">
                        <Icon fontSize="small">close</Icon>
                    </IconButton>
                )}
            </div>
            <div
                className={classNames('formats-list__search', {
                    'formats-list__search--compact': compact
                })}>
                <SearchField onChange={handleChange} filters={filters} />
            </div>
            <FormatsListItems
                items={filteredItems}
                selectedItems={localSelectedItems}
                activeItem={activeItem}
                filters={selectedFilters}
                selectable={selectable}
                onClickItem={handleClickItem}
                onSelectItem={onSelectItem}
                handleNewSelectedItems={handleNewSelectedItems}
            />
        </div>
    );
};

export default FormatsList;

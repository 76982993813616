import React from 'react';
import List from 'components/ui-components-v2/List';
import ListItemButton from 'components/ui-components-v2/ListItemButton';
import ListItem from 'components/ui-components-v2/ListItem';
import Checkbox from 'components/ui-components-v2/Checkbox';
import Typography from 'components/ui-components-v2/Typography';
import Translation from 'components/data/Translation';
import { Item } from '..';
import { FormatsListItem } from './format';
import '../styles/formats.scss';

interface Props {
    items: Item[];
    selectable?: boolean; // If this is true, each format will have a checkbox and there is an option to enable all formats at the same time
    selectedItems?: string[];
    activeItem?: string;
    filters?: string[];
    onClickItem: (item: Item) => void;
    onSelectItem: (item: Item) => void;
    handleNewSelectedItems: (items: string[]) => void;
}

const FormatsListItems: React.FC<Props> = ({ items, selectable, selectedItems, activeItem, onClickItem, onSelectItem, handleNewSelectedItems }) => {
    const selectAllChecked = items.every((format) => selectedItems?.includes(format.key));

    const isIndeterminate = (() => {
        const checkedCount = items.filter((filteredFormat) => selectedItems?.includes(filteredFormat.key)).length;
        return checkedCount > 0 && checkedCount < items.length;
    })();

    /**
     * Handle the actions if the user checks or unchecks the "enable all" checkbox
     */
    const toggleAllItems = () => {
        if (selectAllChecked || isIndeterminate) {
            const newSelectedItems = selectedItems?.filter((selectedFormat) => !items.map((format) => format.key).includes(selectedFormat));
            handleNewSelectedItems(newSelectedItems || []);
        } else {
            const newSelectedItems = items.map((item) => item.key);
            handleNewSelectedItems(newSelectedItems);
        }
    };

    // Get the label for the "enable all" checkbox
    const getToggleAllLabel = () => {
        if (selectAllChecked || isIndeterminate) {
            return Translation.get('formatsList.labels.disableAll', 'creatives-v2');
        }

        return Translation.get('formatsList.labels.enableAll', 'creatives-v2');
    };

    return (
        <List>
            {selectable && items?.length > 0 && (
                <ListItem disableGutters disablePadding>
                    <ListItemButton disableGutters onClick={() => toggleAllItems()}>
                        <Checkbox checked={selectAllChecked} indeterminate={isIndeterminate} size="small" />
                        <Typography variant="body2">{getToggleAllLabel()}</Typography>
                    </ListItemButton>
                </ListItem>
            )}
            {!items || (items.length === 0 && <div className="bricks-formats-list-items__empty">No formats</div>)}
            {items?.length > 0 &&
                items.map((item: Item) => (
                    <FormatsListItem
                        key={item.title}
                        item={item}
                        selected={selectedItems?.includes(item.key)}
                        selectable={selectable}
                        active={!!activeItem && activeItem === item.key}
                        onClickItem={onClickItem}
                        onSelectItem={onSelectItem}
                    />
                ))}
        </List>
    );
};

export { FormatsListItems };
